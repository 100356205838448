@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;400&display=swap');
/* Lato Barlow 'IBM Plex Sans' */
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;1,100;1,200;1,300&family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;1,100;1,200;1,300&family=Lato:ital,wght@0,100;0,300;0,400;1,100;1,300&display=swap');

:root {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    --dark-blue: rgb(49, 67, 103);
    --main-font: 'Barlow', sans-serif;
    --other-font: 'Playfair Display', serif;
    font-size: 14px;
    --scroll-bar-width: 20px;
    --center-width: 750px;
    --background-opacity: 0.3;
}

html, body {
    max-width: 100%;
    margin: 0;
}

a:-webkit-any-link {
    color: inherit;
}

a {
    text-decoration: none;
}

* {
    margin: 0;
    padding: 0;
    font-family: var(--main-font);
}

h1, h2 {
    margin: 0px 0px 10px 0px;
    color: var(--dark-blue);
}

#main-container {
    background-color: rgba(255, 255, 255, var(--background-opacity));
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0;
    overflow: hidden;
}

#background-container {
    overflow: hidden;
    position: absolute;
    top: 0px;
    z-index: -1;
}

#top-bar-container {
    width: 100%;
    background-color: white;
    box-shadow: 7px 7px 15px rgba(0, 0, 0, 0.2);
    border-bottom: 4px solid var(--dark-blue);
    align-items: center;
    position: fixed;
    top: 0
}

#top-bar-elements-container {
    display: flex;
    margin-left: calc((100vw - var(--center-width)) / 2);
    width: var(--center-width);
    align-items: center;
}

#top-bar-elements-container .logo {
    height: 50px;
    padding: 15px 0 15px;
    display: inline-flex;
}

#top-bar-elements-container .navigation-container {
    display: inline-flex;
    font-size: 18px;
}

#top-bar-elements-container .navigation-element {
    padding: 0 10px 0 10px;
}

#top-bar-elements-container a {
    text-decoration: none;
    color: rgb(133, 141, 156);
    transition-duration: 100ms;
}

#top-bar-elements-container a:hover {
    color: rgb(0, 0, 0);
}

#big-text {
    background-color: var(--dark-blue);
    display: flex-box;
    width: var(--center-width);
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 45px;
    font-weight: 300;
    border-radius: 10px 10px 0px 0px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
}

#description-container {
    display: flex-box;
    background-color: white;
    margin-bottom: 0px;
    width: var(--center-width);
    margin-top: 20px;
    border-top: 4px solid var(--dark-blue);
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0);
    border-radius: 0 0 10px 10px;
}

#page-container {
    width: 100%;
    display: flex;
    min-height: 20px;
    justify-content: center;
}

#page {
    width: var(--center-width);
    background-color: white;
    min-height: 30vh;
    border-radius: 10px 10px;
}

major-heading {
    font-size: 40px;
    display: block;
    border-bottom: 2px solid var(--dark-blue);
    padding: 0 5px 10px 5px;
    margin-bottom: 15px;
    color: var(--dark-blue);
}

.centered-major-heading-container {
    display: flex;
    width: 100%;
    justify-content: center;
}

.centered-major-heading {
    width: calc(100% - 80px);
    text-align: center;
    font-size: 35px;
    padding: 15px 0 10px 0;
    border-bottom: 2px solid var(--dark-blue);
}

#bottom-bar-flex-aligner {
    display: flex;
    background-color: var(--dark-blue);
    min-height: 20px;
    padding: 25px 0 25px;
    justify-content: center;
    flex-direction: row;
}

#bottom-bar-container {
    width: var(--center-width);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#bottom-bar-elements-container {
    display: flex;
    align-items: center;
}

#bottom-bar-elements-container .navigation-container {
    font-size: 14px;
    color: rgb(200, 200, 200);
    font-weight: 400;
    margin-left: 50px;
    display: flex;
    flex-direction: row;
}

#bottom-bar-container img {
    padding: 20px;
    height: 40px;
}

.team-member {
    display: flexbox;
    padding: 20px;
    height: fit-content;
}

@media screen and (max-width: 800px) {
    #big-text, #description-container, #bottom-bar-container, .page {
        width: 90vw;
    }

    #big-text {
        font-size: 40px;
    }

    #top-bar-container .logo {
        height: 45px;
    }

    #bottom-bar-elements-container img {
        height: 30px;
    }

    #top-bar-elements-container {
        flex-direction: column;
        padding-bottom: 15px;
    }

    #top-bar-elements-container .navigation-container {
        font-size: 14px;
    }

    #bottom-bar-elements-container .navigation-container {
        margin-left: 15px;
    }
}

/* #region Services */

#service-page-container {
    display: flex;
}

#service-selector-container {
    display: flex;
    background-color: rgb(255, 255, 255);
    flex-direction: column;
    padding: 0 0 5px 5px;
    min-width: 20%;
    border-right: 1px solid var(--dark-blue);
    margin-right: 10px;
}

#service-selector-container a {
    text-decoration: none;
}

.selected-service, .unselected-service {
    text-decoration: none;
    font-size: 18px;
    text-align: center;
    padding: 10px;
    transition-duration: 200ms;
}

.unselected-service {
    color: rgb(134, 137, 153);
}

.selected-service {
    color: white;
    background-color: var(--dark-blue);
}

.unselected-service:hover {
    color: var(--dark-blue);
}

.service h1 {
    font-weight: 300;
    text-align: center;
    margin: 5px 0 5px;
}

.service img {
    width: 100%;
    border-radius: 5px;
    margin: 10px 0 10px;
}

.service div {
    font-size: 18px;
}

@media screen and (max-width: 800px) {
    #service-selector-container {
        flex-direction: column;
        flex-wrap: wrap;
        margin-right: 0px;
        margin-bottom: 20px;
        border-right: 0;
        border-bottom: 1px solid var(--dark-blue);
    }

    #service-page-container {
        flex-direction: column;
    }

    major-heading {
        font-size: 35px;
    }

    .unselected-service, .selected-service {
        font-size: 14px;
    }
}

/* #endregion Services */

.article-widget {
    border-bottom: 1px solid rgb(133, 141, 156);
    max-width: 100%;
    padding-bottom: 20px;
    margin: 0px 10px 20px 10px;
    transition-duration: 200ms;
    box-shadow: 0px 0px 0px rgb(0, 0, 0, 0.0);
}

.article-widget:hover {
    background-color: rgb(250, 250, 250);
    color: rgb(0, 0, 0);
    box-shadow: 3px 3px 10px rgb(0, 0, 0, 0.2);
    border-radius: 4px;
}

.article-widget .text-container {
    width: 70%;
    display: inline-block;
    margin-right: 15px;
}

.article-widget .img-container {
    overflow: hidden;
    width: 30%;
}

.article-widget img {
    display: flexbox;
    width: 100%;
    border-radius: 4px;
}

.article-widget .title {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 10px;
}

.article-widget .big-meta {
    display: flex;
    flex-direction: row;
}

.article-widget .big-meta:hover {
    cursor: pointer;
}

.article-widget .small-meta {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

.article-widget .small-meta * {
    display: flexbox;
    padding: 5px 0px 5px 10px;
}

.article-widget .description-mobile {
    visibility: hidden;
    height: 0;
    width: 0;
}

@media screen and (max-width: 800px) {
    .article-widget .description-mobile {
        visibility: visible;
        height: fit-content;
        width: 100%;
    }

    .article-widget .description {
        visibility: hidden;
        height: 0;
        width: 0;
    }

    .article-widget .img-container {
        overflow: hidden;
        width: 50%;
    }
}

/* #region article */

.article .big-meta-positioner {
    display: flex;
    flex-direction: row;
    padding-bottom: 20px;
    align-items: flex-end;
}

.article .return-to-blog {
    display: flex;
    min-width: fit-content;
    margin-right: 10px;
    color: var(--dark-blue);
    align-items: center;
}

.article .return-to-blog:hover {
    cursor: pointer;
    color: rgb(0, 0, 0);
}

.article .title {
    font-size: 40px;
    font-weight: bold;
    color: var(--dark-blue);
    display: flexbox;
}

.article .small-meta-positioner {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
}

.article .small-meta-positioner img {
    height: 18px;
    width: 18px;
    margin: 0 5px 0 0;
    display: flexbox;
}

.article .small-meta {
    display: flex;
    flex-direction: row;
    margin-right: 10px;
    color: rgb(133, 141, 156)
}

.small-meta-item {
    min-width: fit-content;
}

.article .small-meta div {
    padding-left: 10px;
}

.article h1 {
    font-weight: 500;
}

.article h2 {
    font-weight: normal;
}

.article img {
    width: 100%;
    border-radius: 4px;
    margin: 5px 0 5px 0;
}

.article {
    font-size: 18px
}

.article img {
    width: 100%;
    border-radius: 4px;
    margin: 5px 0 5px 0;
}


/* #endregion article */

/* #region contact */

.contact-widget-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 0px 20px 0px;
}

.contact-widget-container {
    font-size: 18px;
    line-height: 28px;
}

.contact-widget-container a {
    color: blue;
}

/* #endregion contact */